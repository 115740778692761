<template>
  <div class="item_list">
    <van-nav-bar
      title="费用申请"
      left-arrow
      safe-area-inset-top
      @click-left="navBack"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="number"
        readonly
        clickable
        name="money"
        label="申请金额"
        :placeholder="`请输入金额，最多可申请￥${actualMoney}`"
        :rules="[
          { required: true },
          { validator, message: `金额大于￥${actualMoney}` }
        ]"
        class="mt-10"
        @touchstart.native.stop="showNumpad = true"
      />
      <van-number-keyboard
        v-model="number"
        :show="showNumpad"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        :maxlength="8"
        @blur="blurNumpad"
      />
      <van-field
        name="pic"
        label="上传团建照片(最多十张)"
        placeholder="请上传照片"
        class="mt-10"
        :rules="[{ required: true, message: '请上传团建时的照片' }]"
      >
        <template #input>
          <van-uploader
            v-model="uploadpic"
            :before-read="beforeRead"
            :before-delete="beforeDelete"
            multiple
            :max-count="10"
            :after-read="afterRead"
          />
        </template>
      </van-field>
      <van-field
        name="invoice"
        label="费用发票照片(最多六张)"
        :rules="[{ required: true, message: '请上传团建费用发票' }]"
      >
        <template #input>
          <van-uploader
            v-model="uploadinvoice"
            :before-read="beforeRead"
            :before-delete="beforeDelete"
            multiple
            :max-count="6"
            :after-read="afterRead"
          />
        </template>
      </van-field>
      <div class="m-16 mt-30">
        <van-button
          :loading="loading"
          block
          type="primary"
          class="br-6"
          native-type="submit"
        >
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  Divider,
  Form,
  Field,
  Calendar,
  Popup,
  NumberKeyboard,
  Dialog,
  Button,
  Toast,
  Uploader,
  NavBar
} from 'vant'
// eslint-disable-next-line no-unused-vars
import { teamBuildLogDetail, teamBuildLogAgain, imgUpload } from '@/api/home'
import errorhandle from '@/mixins/errorhandle'
export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Form.name]: Form,
    [Divider.name]: Divider,
    [NumberKeyboard.name]: NumberKeyboard,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar,
    [Uploader.name]: Uploader,
    [Dialog.Component.name.name]: Dialog.Component.name,
    [Toast.name]: Toast,
    [Field.name]: Field
  },
  mixins: [errorhandle],
  props: {
    id: {
      type: String,
      default: ''
    },
    actualMoney: {
      type: String,
      default: '1'
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      number: '',
      showNumpad: false,
      loading: false,
      maxcost: 45,
      uploadpic: [],
      uploadinvoice: [],
      searchVal: '',
      editable: false,
      pattern: /^1[3456789]\d{9}$/
    }
  },
  computed: {
    numberS() {
      return this.number === '' ? '' : `${this.number}人`
    }
  },
  created() {},
  activated() {
    console.log('i cost activated')
    console.log(this.detail)
  },
  methods: {
    navBack() {
      this.$router.go(-1)
    },
    onConfirm() {
      console.log('onConfirm')
    },
    async onSubmit(values) {
      console.log('submit', values)
      Toast.loading({
        duration: 0,
        forbidClick: true
      })
      try {
        await this.doTBLogAgain(values)
        console.log('doTBLogAgain complete')
        Toast.clear()
        Dialog.confirm({
          title: '申请成功',
          message: '请耐心等待审核',
          showCancelButton: false,
          confirmButtonText: '我知道了'
        }).then(() => {
          this.detail.status = 2
          this.$router.go(-2)
        })
      } catch (err) {
        Toast.clear()
        Toast.fail(this.catchneterr(err.message))
      }
      console.log('submitcomplete')
    },
    validator(val) {
      return val <= this.actualMoney
    },
    blurNumpad() {
      console.log('blurNumpad')
      // 去除前面多余的0
      // this.number = this.number.replace(/^0+/, '')
      this.number = String(parseFloat(this.number).toFixed(2))
      // 取小数点后两位
      // this.number = this.number.replace(/([0-9]+\.[0-9]{2})[0-9]*/, '$1')
      // 去掉小数点后多余的0
      this.number = this.number.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
      this.showNumpad = false
    },
    beforeRead(file) {
      let deviceFile = []
      let rest = true
      if (Array.isArray(file)) {
        deviceFile = file
      } else {
        deviceFile.push(file)
      }
      deviceFile.forEach(item => {
        if (item.type !== 'image/jpeg' && item.type !== 'image/png') {
          Toast('请上传 jpg/png 格式图片')
          rest = false
        }
      })
      return rest
    },
    // TODO: 删除图片，现无后台接口
    beforeDelete(file) {
      console.log(file)
      return true
    },
    afterRead(file) {
      // console.log('afterRead')
      // console.log(file)
      let deviceFile = []
      if (Array.isArray(file)) {
        deviceFile = file
      } else {
        deviceFile.push(file)
      }
      deviceFile.forEach(item => {
        item.status = 'uploading'
        item.message = '上传中...'
        this.douploadpic(item)
      })
    },
    async douploadpic(item) {
      let formData = new FormData()
      // console.log('选择值', item)
      formData.append('agent_img', item.file, item.file.name)
      // console.log('formData值', formData.getAll('agent_img'))
      try {
        const res = await imgUpload(formData)
        // console.log(res)
        item.status = 'done'
        item.message = '上传成功'
        // item = { ...{ content: res.result }, ...item }
        item.result = res.result
        console.info('上传成功')
        console.info(item)
      } catch (err) {
        item.status = 'failed'
        item.message = '上传失败'
        throw '图片上传失败' + err
      }
    },
    async doTBLogAgain(data) {
      console.log(data)
      try {
        let imgs = data.pic.map(i => i.result)
        let bills = data.invoice.map(i => i.result)
        let postdata = {
          id: parseInt(this.id),
          money: parseFloat(data.money),
          imgs: imgs,
          bills: bills
        }
        console.log(postdata)
        const res = await teamBuildLogAgain(postdata)
        console.log('提交数据成功')
        if (res.status === 0) throw { message: '提交费用失败' + res.msg }
        return res
      } catch (err) {
        console.error(err.message)
        throw err
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.mt-10 {
  margin-top: 10px;
}
.m-16 {
  margin: 16px;
}
.mt-30 {
  margin-top: 30px;
}
.br-6 {
  border-radius: 6px;
}
</style>
