export default {
  methods: {
    handleErrorMsg(msg) {
      if (msg.message === undefined) return msg
      else if (String(msg.message).includes('Network Error')) return '网络错误'
      else if (String(msg.message).includes('timeout')) return '网络连接超时'
      else return msg.message
    }
  }
}
